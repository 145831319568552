export const RoomMode = {
  Lobby: 0,
  Game: 1,
};

export const PresenceStatus = {
  Online: 0,
  Offline: 1,
};

export const TurnStatus = {
  Thinking: 0,
  Ended: 1,
};

export const AttackOutcome = {
  Win: 0,
  Lose: 1,
};
