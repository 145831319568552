export const CHAT_MESSAGE_RECEIVED = 'ChatMessageReceived';
export const PLAYER_JOINED = 'PlayerJoined';
export const PLAYER_UPDATED = 'PlayerUpdated';
export const LEADER_CHANGED = 'LeaderChanged';
export const MAP_UPDATED = 'MapUpdated';
export const USER_STATUS_CHANGED = 'UserStatusChanged';
export const GAME_STARTED = 'GameStarted';
export const GAME_TICKED = 'GameTicked';
export const TURN_CALCULATION_STARTED = 'TurnCalculationStarted';
export const TURN_CALCULATION_ENDED = 'TurnCalculationEnded';
export const ATTACK_HAPPENED = 'AttackHappened';
export const TURN_ENDED = 'TurnEnded';
export const GAME_ENDED = 'GameEnded';
